import React, { Suspense, lazy } from "react";
import {useState , useEffect} from "react";

import ScrollToTop from "../components/ScrollToTop";

import mainBanner from "../assets/img/main_banners/RetailManagementpage.webp";
import DetailImg from "../assets/img/retailManagement/EnuvisioncomprehensiveRetailManagementTraining.jpg";
import resourceBackImg from "../assets/img/linesbackground/KeyFeatures/1.png";

const Navbar = lazy(() => import("../components/Navbar"));
const ServiceMainPage = lazy(() => import("../components/ServiceMainPage"));
const ServiceDetailPage = lazy(() => import("../components/ServiceDetailPage"));
const ResourceTable = lazy(() => import("../components/ResourceTable"));
const Footer = lazy(() => import("../components/footer"));

const mainContent = {
  heading: "Retail Management Training",
  title: "",
  para: "",
  button: "Book a Consultant",
  image: mainBanner,
};

const data = {
  heading: "Enuvision comprehensive Retail Management Training",
  para: "Enuvision comprehensive Retail Management Training Program is designed to equip professionals with the essential skills and knowledge required to excel in the dynamic and fast-paced retail industry. This program covers a wide array of topics, from fundamental retail principles to advanced management strategies, ensuring participants are well-prepared to meet the challenges of the modern retail environment.",
  image1: DetailImg,
  points: [],
};

const resourceData = {
  heading: "Key Features",
  para: "",
  back_img: resourceBackImg,
  columns: [
    {
      title: "",
      points: [
        { point: "Retail Product Hierarchy Creation", subpoint: [] },
        { point: "Main Warehouse", subpoint: [] },
        { point: "Shop (Store)", subpoint: [] },
        { point: "Payment Method", subpoint: [] },
        { point: "Income/Expense Account", subpoint: [] },
      ],
    },
    {
      title: "",
      points: [
        { point: "POS terminals", subpoint: [] },
        { point: "Barcode", subpoint: [] },
        { point: "Stock Distribution (Push Pull Push Method)", subpoint: [] },
        { point: "Stock dispatching from warehouse", subpoint: [] },
        { point: "Stock receiving at warehouse", subpoint: [] },
        { point: "Staff Management", subpoint: [] },
        { point: "Recipe Management", subpoint: [] },
        { point: "Statement Posting", subpoint: [] },
        { point: "Barcode", subpoint: [] },
      ],
    },
    {
      title: "",
      points: [
        {
          point: "POS Processes",
          subpoint: [
            "Normal Sales",
            "Return Balance",
            "Customer Sales Receipt",
            "Create Customer Order from Store",
            "Inventory lookup to check stock availability at warehouse",
            "Scan items, search customer and create customer order",
            "Shipping and delivery",
            "Full advance payment automatically",
            "Discounted Sales",
            "Discounted Sales: Season Discount",
            "Discounted Sales: Employee Discounts",
            "Loyalty Points Earning/Redemption",
            "Customer Returns",
            "Gift Card Issuance",
            "Gift Card Redemption",
            "Stock Dispatching From Shop",
            "Stock Receiving at Shop",
            "Stock Count (Stock Audit) at Shop",
            "Customer Creation",
            "Expense Management",
            "Cash Remittance",
            "Day End/Batch Closing",
          ],
        },
        { point: "Reports", subpoint: [] },
      ],
    },
  ],
};

export const RetailManagement = () => {
  const [loadedBanner , setLoadedBanner] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = mainContent.image;
    img.onload = () => {
      setLoadedBanner(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContent.image]);

  if(!loadedBanner){
    return;
  }

  return (
    <div className="RetailManagement">
      <ScrollToTop />
      <Suspense>
        <Navbar />
      </Suspense>
      <Suspense>
        <ServiceMainPage
          mainContent={mainContent}
        />
      </Suspense>
      <Suspense>
        <ServiceDetailPage details={data} />
      </Suspense>
      <Suspense>
        <ResourceTable data={resourceData} />
      </Suspense>
      <Suspense>
        <Footer />
      </Suspense>
    </div>
  );
};

export default RetailManagement;
